// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyANXn-wqDdF7yi7-btnT4TkM2zyVfmusdA",
  authDomain: "pokemon-galactic-webstore.firebaseapp.com",
  projectId: "pokemon-galactic-webstore",
  storageBucket: "pokemon-galactic-webstore.appspot.com",
  messagingSenderId: "666118266980",
  appId: "1:666118266980:web:5b7960cd6349fd3c41ff71",
  measurementId: "G-PJ712D0Q2Q"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);