import React from 'react';
import CartCards from './cart_cards';

export function Cart() {
  return (
    <>
      <CartCards />
    </>
  );
}
