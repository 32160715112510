import React from 'react';
import { ItemCards } from './items_cards';

export function Items() {
  return (
    <>
      <ItemCards />
    </>
  );
}
